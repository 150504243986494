<template>
    <div class="news_child_en_div">
        <div class="nav_div"></div>
        <div class="news_content_div allContentWidth">
            <div class="return_box">
                <span @click="backTopPage"><< Return to list of news events</span>
            </div>
            <div class="news_content_box">
                <div class="news_title">
                    <p class="news_title_span">{{ contentObj.title }}</p>
                    <p class="news_time_span">{{ contentObj.time }}</p>
                </div>
                <div class="new_content">
                    <p v-for="a in contentObj.contentArr" :key="a.id"
                       :style=" a.bold?'font-weight:bold;':'font-weight:normal;'">
                        {{ a.text }}
                    </p>
                </div>
                <div class="news_img_div">
                    <div v-for="a in contentObj.img_url">
                        <img v-if="a.height" :style="'height:'+a.height" :src="a.url"/>
                        <img v-if="!a.height" :src="a.url"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    computed: {},
    mounted() {
        this.initData();
    },
    watch: {},
    methods: {
        backTopPage() {
            this.$router.push(`/news_en`);
        },
        initData() {
            this.contentAll.company.map((item) => {
                if (item.key === Number(this.$route.params.id)) {
                    this.contentObj = item;
                }
            })
        },
    },
    data() {
        var imgSrc = [];
        imgSrc.push(require('../../assets/img/aboutUs/about_banner.png'));//(0)
        return {
            imgSrc: imgSrc,
            contentObj: {},
            contentAll: {
                'industry': [
                    {id: 1, content: '关于出口舱单新规的通知', time: '2018.12.01'},
                ],
                'company': [
                    {
                        key: 1,
                        title: 'Eastrong Shanghai Branch held a birthday party for employees',
                        time: 'June 24,2022',
                        contentArr: [
                            {
                                id: 101,
                                text: 'The epidemic has influence on our daily life, but we still provide our special care to our employee. Eastrong brings special blessings to the employees whose birthday in March, April and May.This quarters birthday party is wonderful with different kinds of cakes.'
                            },
                        ],
                        img_url: [{url: require('../../assets/img/home_birthday.jpg')}]
                    },
                    {
                        key: 2,
                        title: 'Eastrong Shanghai Branch was awarded CEIV certification',
                        time: 'March 3,2022',
                        contentArr: [
                            {
                                id: 101,
                                text: 'Eastrong International Logistics Co., Ltd. Shanghai Branch was awarded CEIV Pharma certification by the International Air Transport Association (IATA) Center of Excellence.'
                            },
                            {
                                id: 102,
                                text: 'Warmly celebrate Eastrong Shanghai Branch was awarded the IATA CEIV Pharma certification.Due to the particularity, safety and timeliness of drug transportation, strict standards should be implemented in all operation links. '
                            },
                            {
                                id: 103,
                                text: 'CEIV Pharma, as the worlds authoritative pharmaceutical logistics validation, Eastrong was awarded this certification through multiple audit.'
                            },
                            {
                                id: 104,
                                text: 'Eastrong will uphold the entrepreneurial spirit of customer first, committed to providing better service to customers around the world.'
                            }
                        ],
                        img_url: [{url: require('../../assets/img/1551655072440_.pic.png')}]
                    },
                    {
                        key: 3,
                        title: 'Eastrong Shanghai held a birthday party for their employee in August.',
                        time: '2022.08.26',
                        contentArr: [
                            {
                                id: 101,
                                text: 'Shanghai has been experienced the longest heat wave on record. This August, we offered the ice-cream cake and iced beverage for our birthday stars.  May wish all their dreams come true.'
                            },
                        ],
                        img_url: [{url: require('../../assets/img/home_birthday_new1.png')}]
                    },
                    {
                        key: 4,
                        title: 'Eastrong Malaysia Launched Local Warehousing & Transportation Service',
                        time: '2023.01',
                        contentArr: [
                            {
                                id: 101,
                                text: 'Besides SEA and AIR freight To/ From Malaysia, we are also able to provide you other services :'
                            },
                            {id: 102, text: '• Customs Clearance'},
                            {id: 103, text: '• Warehousing'},
                            {id: 104, text: '• Trucking & Nationwide Delivery'},
                            {id: 105, text: '• Cross Boarder (China, Myanmar, Laos, Cambodia, Thailand & Singapore)'},
                            {id: 106, text: '• Courier Services '},
                            {id: 107, text: '• Project Cargo'},
                            {
                                id: 108,
                                text: 'Please advise if you have FCL & LCL shipment from your end to Malaysia as we are getting 50 ' +
                                    'thousand sqft (50,000 sqft) warehouse located in Port Klang to support most of the e-commerce business' +
                                    ' including the transportation services (within Penisular Malaysia) from overseas to Malaysia。'
                            },
                            {
                                id: 109,
                                text: 'Eastrong Malaysia is able to provide the local transport (with 1-5tons) truck for the local transportation within ' +
                                    'Peninsular Malaysia, to support most of the e-commerce & SME(Small & Medium Enterprise) business.'
                            },
                        ],
                        img_url: [
                            {url: require('../../assets/img/home_bnews4_1.png')},
                            {url: require('../../assets/img/home_bnews4_2.png')},
                            {url: require('../../assets/img/home_bnews4_3.png')}
                        ]
                    },
                    {
                        key: 5,
                        title: 'EASTRONG GROUP OBTAINED CCLP（CargoWise Certified Logistics Provider）CERTIFICATION',
                        time: '2023.01',
                        contentArr: [
                            {
                                id: 101,
                                text: 'EASTRONG GROUP obtained Cargowise System CCLP certification in January 2022.'
                            },
                            {
                                id: 102,
                                text: 'The CargoWise Certified Logistics Provider (CCLP) program is designed for CargoWise customers who continuously look ' +
                                    'for ways to support their employees and get even more from the platform.'
                            },
                            {
                                id: 103,
                                text: 'With a focus on increasing the abilities and expertise of workforce through certification, team members' +
                                    ' can work more collaboratively, quickly, and efficiently.'
                            },
                            {id: 104, text: 'Drive business growth', bold: true},
                            {
                                id: 105,
                                text: 'CargoWise certified users are knowledgeable, in-house experts we can count on to help deliver digital transformation initiatives.'
                            },
                            {id: 106, text: 'Improve performance', bold: true},
                            {
                                id: 107,
                                text: 'Once certified, team members have a much better understanding of the system, helping them solve problems faster and deliver better customer service.'
                            },
                            {id: 108, text: 'Faster rollout', bold: true},
                            {
                                id: 109,
                                text: 'With proven project experience and business process knowledge, companies with CargoWise certified users enjoy smoother deployments.'
                            },
                        ],
                        img_url: [{url: require('../../assets/img/home_bnews5_1.png'), height: '200px;'}]
                    },
                    {
                        key: 6,
                        title: 'Eastrong Global Sales Meeting 2023',
                        time: '2023.05',
                        contentArr: [
                            {
                                id: 101,
                                text: 'Eastrong Global Sales Meeting 2023 was held in Hochiminh on March.17 and 18, which summarized the insufficiency in 2022 and put forward new requirement in 2023. '
                            },
                        ],
                        img_url: [{url: require('../../assets/img/home_bnews6_1.jpeg')}]
                    },
                    {
                        key: 7,
                        title: 'Team Building in Chengdu 2023',
                        time: '2023.05',
                        contentArr: [
                            {
                                id: 101,
                                text: 'Eastrong had a team building activity in Chengdu from April.21 to April.23 to activate the working passion, strengthen mutual communication and confidence,enhance the responsibility and sense of belonging.'
                            },
                        ],
                        img_url: [{url: require('../../assets/img/home_bnews7_1.jpeg')}]
                    },
                    // {
                    //     key: 8,
                    //     title: 'Warm congratulations on Eastrong International Logistics Co., LTD. of Taiwan office set up',
                    //     time: '2023.09',
                    //     contentArr: [
                    //         {
                    //             id: 102,
                    //             text: 'Warm congratulations on Eastrong International Logistics Co., LTD. of Taiwan office set up'
                    //         }
                    //     ],
                    //     img_url: [{url: require('../../assets/img/taiwan-gs1.jpg')}, {url: require('../../assets/img/taiwan-gs2.jpg')}]
                    // },
                    {
                        key: 9,
                        title: 'Eastrong AGM 2024',
                        time: '2024.02',
                        contentArr: [
                            {
                                id: 103,
                                text: 'Eastrong AGM 2024 was held in Shanghai on January 4 and 5,during which we reviewed what we did in 2023, analyzed current situation and tasks and made proper arrangement of new assisgnment in 2024.'
                            }
                        ],
                        img_url: [{url: require('../../assets/img/2024nh1.jpg')}, {url: require('../../assets/img/2024nh2.jpg')}, {url: require('../../assets/img/2024nh3.jpg')}]
                    }

                ],
            }
        }
    }
}
</script>
<style lang="less">
.news_child_en_div {
  .news_content_div {
    .return_box {
      margin: 50px 0 50px 0;
      cursor: pointer;

      span {
        font-size: 14px;
      }
    }

    .news_content_box {
      .news_title {
        text-align: center;
        margin: 30px 0 30px 0;

        .news_title_span {
          font-size: 20px;
          font-weight: 700;
        }

        .news_time_span {
          font-size: 16px;
        }
      }

      .new_content {
        line-height: 58px;
        width: 80%;
        margin: 0 auto;
      }

      .news_img_div {
        width: 100%;
        margin: 30px auto 40px;

        div {
          margin-bottom: 10px;
          text-align: center;

          img {
            height: 350px;
            display: inline-block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .news_child_en_div {
    .news_content_div {
      padding: 0 15px;

      .return_box {
        padding-left: 0px;
      }

      .news_content_box {
        .news_img_div {
          width: 100%;
          height: auto;

          div {
            img {
              height: auto !important;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
